<template>
  <div class="floorplan" :class="['floorplan--size-' + size]">
    <transition name="fade" mode="out-in">
      <div class="floorplan__main" :key="current.url">
        <div class="content">
          <component
            class="floorplan__main-image"
            :is="current.Component"
            :value="getValue(current)"
            loop
            :mute="false"
            @mouseleave.native="start"
            @mouseenter.native="stop"
            @playing="playing = true"
            @ended="ended"
            @click.native="attach"
            :size="size"
            ref="current"
          />
          <caption v-if="current.caption" class="floorplan__caption">
            {{
              current.caption
            }}
          </caption>
        </div>

        <a
          v-if="current.type == 'application/pdf'"
          :href="current.url"
          target="_blank"
          class="floorplan__main-link"
        >
          <FontAwesomeIcon :icon="['far', 'external-link-alt']" size="lg" />
        </a>
      </div>
    </transition>

    <div class="floorplan__thumbnails">
      <div v-for="(item, i) in valueComp" :key="i" class="floorplan__slide">
        <div
          v-if="valueComp.length > 1"
          class="floorplan__thumbnail"
          :class="{ 'floorplan__thumbnail--selected': index === i }"
        >
          <div class="content" @click="goto(i)">
            <component
              :size="size"
              :is="item.Component"
              class="floorplan__thumbnail-image"
              :value="item"
              loop
              :mute="false"
              hide-consent
            />
          </div>
        </div>
      </div>
    </div>

    <div :id="scrollAnchor" class="scroll-anchor"></div>
  </div>
</template>

<script>
import { Slide, Carousel } from 'vue-carousel'
import MediumZoom from 'medium-zoom'
import { Types } from '@kvass/media-render'
import LazyLoad from 'vue-make-lazy'

export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    scrollAnchor: {
      type: String,
      default: 'images',
    },
    size: {
      type: String,
      enum: ['cover', 'contain'],
      default: 'contain',
    },
    timeout: {
      type: Number,
      default: 6000,
    },
  },
  watch: {
    current: {
      handler: 'attach',
      immediate: true,
    },
  },
  data() {
    return {
      index: 0,
      runner: null,
      playing: false,
      zoomer: MediumZoom([], {
        scrollOffset: 20,
        background: 'rgba(255, 255, 255, .9)',
      }),
    }
  },
  computed: {
    valueComp() {
      return (this.data.content || []).map(item => ({
        caption: item.name,
        url: item.url,
        type: item.type,
        Component: this.getComponent(item.type),
      }))
    },
    current() {
      return this.valueComp[this.index] || {}
    },
    slideIndex() {
      return Math.floor(this.index / (this.$path('$refs.carousel.breakpointSlidesPerPage') || 1))
    },
    meta() {
      if (!this.valueComp.length) return
      return {
        next:
          this.index === this.valueComp.length - 1
            ? this.valueComp[0]
            : this.valueComp[this.index + 1],
        prev:
          this.index === 0
            ? this.valueComp[this.valueComp.length - 1]
            : this.valueComp[this.index - 1],
      }
    },
  },
  methods: {
    attach() {
      this.detach()

      if (!this.$refs.current) return
      this.zoomer.attach(this.$refs.current.$el.querySelector('img'))
    },
    detach() {
      if (!this.zoomer) return
      this.zoomer.detach()
    },
    getComponent(type) {
      switch (type) {
        case 'youtube':
          return Types.Youtube
        case 'vimeo':
          return Types.Vimeo
        default:
          return Types.Image
      }
    },
    goto(index) {
      this.playing = false
      this.index = index
      this.start()
    },
    ended() {
      this.playing = false
      this.next()
    },
    next() {
      if (this.playing) return
      this.index = this.index === this.valueComp.length - 1 ? 0 : this.index + 1
      this.start()
    },
    prev() {
      if (this.playing) return
      this.index = this.index === 0 ? this.valueComp.length - 1 : this.index - 1
      this.start()
    },
    start() {
      if (this.playing) return
      this.stop()
      this.runner = setTimeout(() => this.next(), this.timeout)
    },
    stop() {
      if (this.runner) clearTimeout(this.runner)
    },
    getValue(item) {
      if (['vimeo', 'youtube'].includes(item.type)) return item
      return [
        {
          pattern: /application\/pdf/,
          url: `${item.url}?convert=png`,
          type: item.type,
        },
        { pattern: /.+/, url: item.url, type: item.type },
      ].find(e => e.pattern.test(item.type))
    },
  },
  mounted() {
    this.start()
  },
  beforeDestroy() {
    this.detach()
  },
  components: {
    Slide,
    Carousel,
    LazyLoad,
  },
  metaInfo() {
    if (!this.meta || ['vimeo', 'youtube'].includes(this.meta.next.type)) return
    return {
      link: [
        {
          rel: 'preload',
          href: this.meta.next.url,
          as: 'image',
        },
      ],
    }
  },
}
</script>

<style lang="scss">
.floorplan {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;

  &__main {
    z-index: 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    min-height: 50vh;
    cursor: zoom-in;
    img {
      max-height: 600px;
    }

    // @include aspect-ratio(16, 9);
    &-link {
      background: rgba(white, 0.5);
      position: absolute;
      bottom: 0;
      right: 0;
      padding: 1em;
    }
    &-image {
      flex-grow: 1;
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }
  &__caption {
    z-index: 1009;
    position: absolute;
    bottom: 1rem;
    font-weight: bold;
    background-color: rgba(white, 0.5);
    padding: 0.5rem 1rem;
    border-radius: 0 $border-radius 0 0;
  }
  &__thumbnail {
    cursor: pointer;
    position: relative;
    height: 100%;
    object-position: center;
    border-radius: $border-radius;

    filter: grayscale(100%);
    transition: filter 150ms ease-out;

    &--selected {
      border: solid 1px var(--primary);
    }
  }
  &__main-image {
    height: 100%;
    object-position: center;
    border-radius: $border-radius;

    // @include aspect-ratio(16, 9);
    .gallery--size-cover & {
      object-fit: cover;
    }
    .gallery--size-contain & {
      object-fit: contain;
    }
  }
  &__thumbnails {
    max-height: 500px;
    overflow-y: auto;
    margin-right: 0.5em;
    align-items: center;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    @include respond-below('tablet') {
      max-height: 400px;
      margin-right: 0;
    }
  }

  &__slide {
    padding: 0.5rem;
  }
}
</style>
